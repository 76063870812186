import styles from "./Services.module.css"

const Services = () => {

    const services1 = [
        {
            title: "GESTION DE PROJETS WEB",
            description: "Site vitrine, corporate, évènementiel, intranet",
            image: "light.png"
        },
        {
            title: "INTÉGRATION WEB",
            description: "Des intégrations HTML/CSS respectueuses des standards web",
            image: "edit.png"
        },
        {
            title: "DÉVELOPPEMENT SPÉCIFIQUE",
            description: "Des outils adaptés à votre coeur de métier, applications et solutions personnalisées",
            image: "settings.png"
        },
        {
            title: "RÉFÉRENCEMENT NATUREL",
            description: "Affichage sémantique des informations, des pages propres pour un référencement optimal",
            image: "checked.png"
        }
    ]

    const services2 = [
        {
            title: "CONCEPTION GRAPHIQUE & WEBDESIGN",
            description: "Logos, templates Web, plaquettes publicitaires, cartes de visite, newsletters...",
            image: "write.png"
        },
        {
            title: "DYNAMISME DES PAGES",
            description: "Des animations de contenu non intrusives pour embellir votre projet",
            image: "refresh.png"
        },
        {
            title: "INTERFACE D'ADMINISTRATION",
            description: "Outils spécifiques au bon fonctionnement de votre entreprise",
            image: "settings2.png"
        },
        {
            title: "RESPONSIVE DESIGN",
            description: "Compatible tous supports, tablette et application mobile",
            image: "retweet.png"
        }
    ]

    return(
        <div className={styles.container} id="services">
            <h1>NOS SERVICES</h1>
            <p>Des prestations adaptées à vos besoins</p>
            <div></div>
            <div className={styles.servicesDetails}>
                <img src="/map.png" alt="Map" />
                <div>
                    {services1.map((service, index: number) => (
                        <div key={index} className={styles.card}>
                            <div>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                            <img src={`/${service.image}`} alt={service.title} />
                        </div>
                    ))}
                </div>
                <div>
                    {services2.map((service, index: number) => (
                        <div key={index} className={styles.card2}>
                            <img src={`/${service.image}`} alt={service.title} />
                            <div>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Services