import styles from "./Header.module.css"

const Header = () => {
    return(
        <div className={styles.container}>
            <a href="#services">SERVICES</a>
            <a href="#skills">COMPÉTENCES</a>
            <a href="#about">À PROPOS</a>
            <a href="#contact">CONTACT</a>
        </div>
    )
}

export default Header