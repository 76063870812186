import styles from "./ProgressBar.module.css"

type Props = {
    percent: number,
    title_bar: string
}

const ProgressBar = ({percent, title_bar}:Props) => {
    return(
        <div className={styles.box}>
            <p style={{width: `${percent}%`}}>{title_bar}</p>
        </div>
    )
}

export default ProgressBar