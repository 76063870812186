import styles from "./About.module.css"

const About = () => {
    return(
        <div className={styles.container} id="about">
            <h1>À PROPOS</h1>
            <p>Nous sommes web designer et développeur web freelance !</p>
            <div></div>
            <div className={styles.content}>
                <div>
                    <h3>Un webdesigner passioné !</h3>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam itaque omnis quisquam, sunt sint dolore, sed assumenda eveniet aspernatur sit quas quasi illum libero pariatur. Vitae voluptate aut excepturi quam!</p>
                    <div><a target="_blank" href="https://my-koppa.com">Portfolio du web designer</a></div>
                </div>
                <img src="/Web_design.png" alt="Web design" />
                <div>
                    <h3>Une expérience en développement</h3>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam itaque omnis quisquam, sunt sint dolore, sed assumenda eveniet aspernatur sit quas quasi illum libero pariatur. Vitae voluptate aut excepturi quam!</p>
                    <div><a target="_blank" href="https://matteo-bonnet.fr">Portfolio du web developer</a></div>
                </div>
            </div>
        </div>
    )
}

export default About