import ProgressBar from "../../components/ProgressBar/ProgressBar"
import styles from "./Skills.module.css"

const Skills = () => {

    const content = [
        {
            title: "Gestion de projet",
            description: "Site vitrine, corporate, évènementiel, intranet.",
            title_bar: "React Js",
            percent: 90
        },
        {
            title: "Application spécifiques et interface d'administration",
            description: "PHP - MYSQL - SQL - NODE JS",
            title_bar: "Node Js - MYSQL - SQL - Prisma - Postgres",
            percent: 80
        },
        {
            title: "Intégration HTML/CSS",
            description: "Codage à la main respectueux des standards du web",
            title_bar: "FIGMA",
            percent: 75
        },
        {
            title: "Dynamisme des pages par JavaScript",
            description: "Prototype, Motools, Scriptaculous",
            title_bar: "SUITE OFFICE",
            percent: 80
        },
        {
            title: "Validation & référencement naturel SEO",
            description: "Accessibilité, ergonomie des pages web",
            title_bar: "In design",
            percent: 60
        }
    ]

    return(
        <div className={styles.container} id="skills">
            <h1>NOS COMPÉTENCES</h1>
            <p>Des compétences à votre service</p>
            <div></div>
            <div className={styles.content}>
                {content.map((skill, index: number) => (
                    <div className={styles.skill}>
                        <div key={index}>
                            <h3>{skill.title?.toUpperCase()}</h3>
                            <p>{skill.description.toUpperCase()}</p>
                        </div>
                        <ProgressBar percent={skill.percent} title_bar={skill.title_bar} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Skills