import styles from "./Steps.module.css"

const Steps = () => {


    const steps = [
        {
            title: "analayse",
            icon: "analyse.png"
        },
        {
            title: "design",
            icon: "design.png"
        },
        {
            title: "code",
            icon: "code.png"
        },
        {
            title: "tests",
            icon: "tests.png"
        },
        {
            title: "livraison",
            icon: "livraison.png"
        }
    ]

    return(
        <div className={styles.container}>
            <h1>" Notre suivi de projet en 5 étapes "</h1>
            <div className={styles.steps}>
                {steps.map((step, index: number) => (
                    <div key={index} className={styles.step}>
                        <p>{index + 1}</p>
                        <div><img src={`/${step.icon}`} alt={step.title} /></div>
                        <p>{step.title.toUpperCase()}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default Steps