import styles from "./Type.module.css"

const Type = () => {
    return(
        <div className={styles.container}>
            <img src="/imac.png" alt="Imac" />
            <img src="/laptop.png" alt="Laptop" />
            <div>
                <p>Compatible Mobile, Tablette et PC</p>
                <h1>Développement Web Responsive</h1>
            </div>
        </div>
    )
}

export default Type