import Services from "./Services/Services"
import Skills from "./Skills/Skills"
import Steps from "./Steps/Steps"
import Title from "./Title/Title"
import Type from "./Type/Type"
import About from "./About/About"
import Footer from "./Footer/Footer"

const Home = () => {
    return(
        <div>
            <Title />
            <Services />
            <Type />
            <Skills />
            <Steps />
            <About />
            <Footer />
        </div>
    )
}

export default Home