import styles from "./Title.module.css"
import Header from "../../components/Header/Header"

const Title = () => {
    return(
        <div className={styles.container}>
            <Header />
            <img src="/imac.png" alt="Imac" className={styles.imac} />
            <img src="/mouse.png" alt="Mouse" className={styles.mouse} />
            <img src="/laptop.png" alt="Laptop" className={styles.laptop} />
            <div className={styles.titles}>
                <h1>Ozzone X Koppa</h1>
                <p>DEUX ÉTUDIANTS, DEUX PASSIONS, UN PROJET.</p>
            </div>
        </div>
    )
}

export default Title