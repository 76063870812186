import styles from "./Footer.module.css"

const Footer = () => {

    return(
        <div className={styles.container} id="contact">
            <img src="/imac.png" alt="Imac" />
            <img src="/laptop.png" alt="Laptop" />
            <h1>" Étudiants ingénieurs du numérique "</h1>
            <a href="mailto:quentin.adeline.koppa@gmail.com"><img src="/mail.png" alt="Mail" className={styles.mail} /></a>
            <p className={styles.rights}>© 2024 - Tous droits réservés</p>
        </div>
    )
}


export default Footer